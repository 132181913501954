import Link from "next/link";
import Image from "next/image";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Container, Grid, GridProps, Typography } from "@material-ui/core";

import { getLocalizedAfpcUrl } from "@/utils";

import styles from "./index.module.scss";

const Footer: React.FC = () => {
  const { t } = useTranslation(["layout"]);
  const { locale: currentLocale } = useRouter();

  const afpcUrl = getLocalizedAfpcUrl(currentLocale!);
  const contactUrl = currentLocale === "fr" ? "https://afpcquebec.com/ecrivez-nous/" : "https://afpcquebec.com/en/contact/";

  const gridItemProps: GridProps = {
    item: true,
    xs: 12,
    sm: 3,
    sx: {
      textAlign: {
        xs: "center",
        sm: "left",
      },
      marginBottom: {
        xs: "2em",
        sm: 0,
      },
    },
  };

  return (
    <footer className={styles.footer}>
      <Container className={styles.main} maxWidth="lg">
        <Grid container spacing={{ xs: 0, sm: 3, md: 5 }}>
          <Grid {...gridItemProps}>
            <address>
              <Typography variant="h5">{t("layout:footer.address.montreal.title")}</Typography>
              <Typography variant="body1">{t("layout:footer.address.montreal.line1")}</Typography>
              <Typography variant="body1" gutterBottom>
                {t("layout:footer.address.montreal.line2")}
              </Typography>
              <Typography variant="body1">
                <a href="tel:5148757100">514-875-7100</a>
                <Typography className={styles.or} component="span">
                  {t("layout:footer.address.or")}
                </Typography>
                <a href="tel:18006428020">1-800-642-8020</a>
                <br />
                <Typography component="span">{t("layout:footer.address.fax")}</Typography>
                <a href="tel:5148758399">514-875-8399</a>
              </Typography>
            </address>
          </Grid>
          <Grid {...gridItemProps}>
            <address>
              <Typography variant="h5">{t("layout:footer.address.quebec.title")}</Typography>
              <Typography variant="body1">{t("layout:footer.address.quebec.line1")}</Typography>
              <Typography variant="body1" gutterBottom>
                {t("layout:footer.address.quebec.line2")}
              </Typography>
              <Typography variant="body1">
                <a href="tel:4186666500">418-666-6500</a>
                <Typography className={styles.or} component="span">
                  {t("layout:footer.address.or")}
                </Typography>
                <a href="tel:18005666530">1-800-566-6530</a>
                <br />
                <Typography component="span">{t("layout:footer.address.fax")}</Typography>
                <a href="tel:4186666999">418-666-6999</a>
              </Typography>
            </address>
          </Grid>
          <Grid
            {...gridItemProps}
            className={styles.logoRed}
            sm={6}
            sx={{
              textAlign: {
                xs: "center",
                sm: "right",
              },
            }}
          >
            <Link href={afpcUrl}>
              <a>
                <Image src="/assets/logo/logo-blanc-rouge.svg" alt="AFPC Logo Red" height={80} width={320} />
              </a>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <div className={styles.links}>
          <a href={contactUrl} target="_blank" rel="noopener noreferrer">
            {t("layout:footer.links.contact")}
          </a>
        </div>
        <div className={styles.socials}>
          <a href="https://www.facebook.com/afpcqc/" target="_blank" rel="noopener noreferrer">
            <FacebookIcon color="primary" />
          </a>
          <a href="https://twitter.com/afpcqc" target="_blank" rel="noopener noreferrer">
            <TwitterIcon color="primary" />
          </a>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
