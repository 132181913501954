import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { Container, Typography } from "@material-ui/core";

import { getLocalizedAfpcUrl } from "@/utils";

import styles from "./index.module.scss";

const Header: React.FC = () => {
  const { locale: currentLocale, locales = [], asPath } = useRouter();
  const afpcUrl = getLocalizedAfpcUrl(currentLocale!);

  const localeSwitcher = locales.length
    ? locales
        .filter((locale) => locale !== currentLocale)
        .map((locale) => (
          <Link href={asPath} locale={locale} key={locale}>
            <a>
              <Typography variant="h5" className={styles.localeSwitcher}>
                {locale}
              </Typography>
            </a>
          </Link>
        ))
    : null;

  return (
    <header className={styles.header}>
      <Container className={styles.container} maxWidth="lg">
        <Link href={afpcUrl}>
          <a className={styles.logo}>
            <Image src="/assets/logo/logo-blanc-horizontal.svg" alt="AFPC Logo White" width={280} height={80} />
          </a>
        </Link>
        {localeSwitcher}
      </Container>
    </header>
  );
};

export default Header;
