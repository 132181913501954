import Head from "next/head";
import { useRouter } from "next/router";
import { Container } from "@material-ui/core";

import Promo from "./Promo";
import Header from "./Header";
import Footer from "./Footer";

import styles from "./index.module.scss";

export type MetaTags = {
  title: string;
  description?: string;
  openGraph?: {
    type: string;
    title: string;
    image?: string;
    description: string;
  };
};

interface Props {
  metaTags: MetaTags;
  noIndex?: boolean;
  children: React.ReactNode;
}

export const Page: React.FC<Props> = ({ metaTags, noIndex = false, children }) => {
  const { asPath, locale } = useRouter();
  const { title, description, openGraph } = metaTags;

  const pagePath = locale === "en" ? `/en${asPath}` : asPath;
  const pageUrl = `${process.env.DOMAIN}${pagePath}`;

  return (
    <>
      <Head>
        <link rel="canonical" href={pageUrl} />
        <title>{`${title} - AFPC-Québec`}</title>
        {description && <meta name="description" content={description} />}
        {openGraph && (
          <>
            <meta property="og:url" content={pageUrl} />
            <meta property="og:type" content={openGraph.type} />
            <meta property="og:site_name" content="AFPC-Québec" />
            <meta property="og:title" content={openGraph.title} />
            <meta property="og:description" content={openGraph.description} />
            {openGraph.image && <meta property="og:image" content={openGraph.image} />}
            {openGraph.image && <meta property="og:image:url" content={openGraph.image} />}
          </>
        )}
        {noIndex && <meta name="robots" content="noindex" />}
      </Head>
      <Header />
      <main className={styles.content}>
        <Container maxWidth="md">{children}</Container>
      </main>
      <Promo />
      <Footer />
    </>
  );
};

export default Page;
