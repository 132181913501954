import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Container, Grid, Typography } from "@material-ui/core";

import styles from "./index.module.scss";

const Promo: React.FC = () => {
  const { t } = useTranslation(["layout", "cta"]);
  const { locale } = useRouter();

  return (
    <section className={styles.promo}>
      <Container maxWidth="md">
        <Grid container spacing={2} alignItems="center">
          <Grid className={styles.image} item sm={3} sx={{ display: { xs: "none", sm: "block" } }}>
            <Image
              src="/assets/cta/cta-phones.png"
              alt="Picture of phone with AFPC app running on it"
              width={200}
              height={200}
              quality="100%"
              objectFit="contain"
            />
          </Grid>
          <Grid item xs sm={9} rowSpacing={8}>
            <Typography variant="h3" gutterBottom align="center" sx={{ textTransform: "uppercase" }}>
              {t("cta:title")}
            </Typography>
            <Typography paragraph gutterBottom align="center">
              {t("cta:description")}
            </Typography>
            <Grid container spacing={2} className={styles.badges} rowSpacing={1} justifyContent="center">
              <a
                href="https://apps.apple.com/us/app/afpc-qu%C3%A9bec/id1607855548?platform=iphone"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: 10 }}
              >
                <Image
                  src={`/assets/badges/${locale}/app-store-badge.svg`}
                  alt="Image of Apple App Store branding"
                  height={40}
                  width={120}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.afpcquebec.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={`/assets/badges/${locale}/google-play-badge.svg`}
                  alt="Image of Google Play Store branding"
                  height={60}
                  width={150}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Promo;
