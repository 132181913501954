import { Langcode } from "@/config/i18n";

export const getLocalizedAfpcUrl = (langcode: string) => {
  return `https://afpcquebec.com/${langcode === Langcode.EN ? "en/home/" : ""}`;
};

export const getFormattedDate = (rawDate: Date, langcode: string) => {
  const date = new Date(rawDate);
  return langcode
    ? date.toLocaleString(langcode, {
        day: "numeric",
        year: "numeric",
        month: "long",
      })
    : date.toString();
};

export const timeToMinute = (time: number) => Math.floor(time) / 1000 / 60;

export const numberToDoubleDigits = (x: number) => (x <= 9 ? `0${x}` : `${x}`);

export const dateToYMD = (date: Date) => {
  const year = date.getFullYear();
  const month = numberToDoubleDigits(date.getMonth() + 1);
  const day = numberToDoubleDigits(date.getDate());

  return `${year}-${month}-${day}`;
};
